import React, { useContext, useEffect } from "react";
import "./FinishOverlay.css";
import { ReactSVG } from "react-svg";
import SudokuContext from "../../../Context/SudokuContext";
import useStopwatchStore from "../../../Stores/stopwatchStore";
import useDifficultyStore from "../../../Stores/difficultyStore";
import { getTimeFromSeconds } from "../GlobalScripts/getTimeFromSeconds";
import { getSQLSudoku } from "../GlobalScripts/getSQLSudoku";
import { getFinishedScore } from "../GlobalScripts/getFinishedScore";
import { Sudoku } from "../Backend/Sudoku";

import gauge_icon from "../../../Images/Icons/gauge.svg";
import time_icon from "../../../Images/Icons/clock.svg";
import score_icon from "../../../Images/Icons/trophy.svg";

function FinishOverlay() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);
  const count = useStopwatchStore((state) => state.count);
  const resetStopwatch = useStopwatchStore((state) => state.resetStopwatch);
  const difficulty = useDifficultyStore((state) => state.difficulty);

  useEffect(() => {
    console.log("test");
  }, []);

  function newSudoku() {
    // Läd ein neues Sudoku aus der SQL Datenbank
    resetStopwatch();
    getSQLSudoku(difficulty).then((sudokuString) => {
      sudokuRef.current = new Sudoku();
      sudokuRef.current.stringUnsolved = sudokuString;
      sudokuRef.current.stringUnsolved2full();
      setSudoku({ ...sudokuRef.current });
    });
  }

  return (
    <div className="FinishOverlay__container">
      <div className="FinishOverlay__heading">Finished</div>
      <div className="FinishOverlay__stats">
        <Statistic_Row
          image={gauge_icon}
          name={"Difficulty"}
          value={difficulty}
        />
        <Statistic_Row
          image={time_icon}
          name={"Time"}
          value={getTimeFromSeconds(count)}
        />
        <Statistic_Row
          image={score_icon}
          name={"Score"}
          value={getFinishedScore(difficulty, count)}
        />
      </div>
      <div className="FinishOverlay__buttons">
        <button className="ButtonPressEffect" onClick={newSudoku}>
          NEW
        </button>
      </div>
    </div>
  );
}

function Statistic_Row(props) {
  return (
    <div className="FinishOverlay__stat-row">
      <div className="FinishOverlay__stat-row-left">
        <ReactSVG src={props.image} className="FinishOverlay__stat-icon" />
        {props.name}
      </div>

      <span>{props.value}</span>
    </div>
  );
}

export default FinishOverlay;
