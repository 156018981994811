import React, { useContext } from "react";
import "./Plugin_Solver.css";
import SudokuContext from "../../../Context/SudokuContext";
import { Solver } from "../Backend/Solver";

function Plugin_Solver() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);

  const handleBacktrackClick = () => {
    sudokuRef.current.full2simple();
    const solver = new Solver(sudokuRef.current);
    solver.backtrackSolve();

    setSudoku(sudokuRef.current);
  };

  return (
    <div className="Solver__container">
      <button onClick={handleBacktrackClick}>Backtrack Solve</button>
    </div>
  );
}

export default Plugin_Solver;
