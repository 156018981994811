import React from "react";
import "./AboutMePage.css";

function AboutMePage() {
  return (
    <div className="AboutMePage__container">
      <div className="AboutMePage__content">
        <h1>About Me</h1>
        <p>
          Hi! My name is <strong>Frieder Uerlings</strong>, and I am the creator
          of this Sudoku website. I developed this platform entirely on my own
          as a personal project.
        </p>
        <p>
          The inspiration for this site came from my love for solving the Sudoku
          puzzles featured in the New York Times. I’ve always been fascinated by
          how Sudoku generators work, and this curiosity drove me to build this
          website.
        </p>
        <p>
          My goal is to provide an enjoyable experience for fellow Sudoku
          enthusiasts. I hope you enjoy using the site as much as I enjoyed
          creating it!
        </p>
        <p>
          If you have any questions, feedback, or ideas, feel free to reach out
          to me at <strong>sudoku.hub.info@gmail.com</strong>
        </p>

        <h2>Imprint</h2>
        <p>
          <strong>Responsible for the content of this website:</strong>
          <br />
          Frieder Uerlings
          <br />
          Gollierstraße 5
          <br />
          80339 Munich
        </p>
        <p>
          <strong>Contact:</strong>
          <br />
          Email:
          <strong> sudoku.hub.info@gmail.com</strong>
        </p>
        <h3>Disclaimer</h3>
        <p>
          Despite careful monitoring of content, I assume no liability for the
          content of external links. The operators of the linked pages are
          solely responsible for their content.
        </p>
      </div>
    </div>
  );
}

export default AboutMePage;
