import React from "react";
import "./SolverPage.css";
import Sudoku_Solver from "../../Components/Sudoku/Sudoku_Apps/Sudoku_Solver";

function SolverPage() {
  return (
    <div className="SolverPage__container">
      <div className="SolverPage__sudokuapp">
        <Sudoku_Solver />
      </div>
    </div>
  );
}

export default SolverPage;
