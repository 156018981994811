import React, { useEffect } from "react";

import Sudoku_Analyzer from "../../Components/Sudoku/Sudoku_Apps/Sudoku_Analyzer";

function AnalyzerPage() {
  return (
    <div className="Sudoku_App__container">
      <Sudoku_Analyzer />
    </div>
  );
}

export default AnalyzerPage;
