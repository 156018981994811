import React, { useContext, useEffect, useState } from "react";
import "./Database.css";
import SudokuContext from "../../../Context/SudokuContext";
import useDifficultyStore from "../../../Stores/difficultyStore";
import Stopwatch from "../Stopwatch/Stopwatch";
import useStopwatchStore from "../../../Stores/stopwatchStore";
import { getSQLSudoku } from "../GlobalScripts/getSQLSudoku";
import { Sudoku } from "../Backend/Sudoku";

function Database() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);
  const { difficulty, setDifficulty } = useDifficultyStore();
  const resetStopwatch = useStopwatchStore((state) => state.resetStopwatch);

  const transparentRBG = "rgba(255,255,255,0)";

  const selectedDiv = {
    backgroundColor: "var(--highlightColor)",
    border: "solid var(--borderColor) 2px",
  };
  const notSelectedDiv = { backgroundColor: transparentRBG };

  // SQL FUNCTION

  useEffect(() => {
    handleDifficultyChange("medium");
  }, []);

  async function handleDifficultyChange(grade_) {
    resetStopwatch();
    setDifficulty(grade_);
    getSQLSudoku(grade_).then((sudokuString) => {
      sudokuRef.current = new Sudoku();
      sudokuRef.current.stringUnsolved = sudokuString;
      sudokuRef.current.stringUnsolved2full();
      setSudoku({ ...sudokuRef.current });
    });
  }

  return (
    <div className="Database__container">
      <div className="Database__Toggle_Background">
        <button
          onClick={() => {
            handleDifficultyChange("easy");
          }}
          style={difficulty == "easy" ? selectedDiv : notSelectedDiv}
        >
          Easy
        </button>
        <button
          onClick={() => {
            handleDifficultyChange("medium");
          }}
          style={difficulty == "medium" ? selectedDiv : notSelectedDiv}
        >
          Medium
        </button>
        <button
          onClick={() => {
            handleDifficultyChange("hard");
          }}
          style={difficulty == "hard" ? selectedDiv : notSelectedDiv}
        >
          Hard
        </button>
      </div>
      <div className="Database__timer">
        <Stopwatch />
      </div>
    </div>
  );
}

export default Database;
