import React, { useState, useContext } from "react";
import SudokuContext from "../../../Context/SudokuContext";
import "./NumPadBar.css";

function NumPadBar() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);
  const transparentRBG = "rgba(255,255,255,0)";

  const selectedDiv = {
    backgroundColor: "var(--highlightColor)",
    border: "solid var(--borderColor) 2px",
  };
  const notSelectedDiv = { backgroundColor: transparentRBG };

  function handleModeSwitch(mode) {
    switch (mode) {
      case "number":
        sudokuRef.current.inputMode = [true, false, false];
        break;
      case "corner":
        sudokuRef.current.inputMode = [false, true, false];
        break;
      case "center":
        sudokuRef.current.inputMode = [false, false, true];
        break;
    }
    setSudoku({ ...sudokuRef.current });
  }

  return (
    <div className="NumPadBar__container">
      <button
        onClick={() => {
          handleModeSwitch("number");
        }}
        style={sudokuRef.current.inputMode[0] ? selectedDiv : notSelectedDiv}
      >
        <div className="NumPadBar__button-content">Number</div>
      </button>
      <button
        onClick={() => {
          handleModeSwitch("corner");
        }}
        style={sudokuRef.current.inputMode[1] ? selectedDiv : notSelectedDiv}
      >
        <div className="NumPadBar__button-content">
          <div>Corner</div>
          <div className="NumPadBar_ButtonSubtext">Note</div>
        </div>
      </button>
      <button
        onClick={() => {
          handleModeSwitch("center");
        }}
        style={sudokuRef.current.inputMode[2] ? selectedDiv : notSelectedDiv}
      >
        <div className="NumPadBar__button-content">
          <div>Center</div>
          <div className="NumPadBar_ButtonSubtext">Note</div>
        </div>
      </button>
    </div>
  );
}

export default NumPadBar;
