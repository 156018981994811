export function getNakedSingles(grid) {
  // Naked Single ist eine Zelle, in der nur eine Zahl möglich ist
  // Länge der Candidates == 1

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (
        grid[row][col].candidates.length === 1 &&
        grid[row][col].value === null
      ) {
        // NS gefunden
        grid[row][col].value = grid[row][col].candidates[0];
        grid[row][col].candidates = [];
        return [grid, true, 2.3];
      }
    }
  }

  return [grid, false, 0];
}
