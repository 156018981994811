import { useEffect, useState, useContext } from "react";
import "./MainGrid.css";
import FinishOverlay from "../FinishOverlay/FinishOverlay";
import PauseOverlay from "../PauseOverlay/PauseOverlay";
import GridComp from "../GridComps/GridComp";
import SudokuContext from "../../../Context/SudokuContext";
import useStopwatchStore from "../../../Stores/stopwatchStore";

function MainGrid() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);
  const isPaused = useStopwatchStore((state) => state.isPaused);

  useEffect(() => {
    let pressTimer;
    let pressTimerFlag;

    function handlePointerDown(event) {
      if (!event.target.closest(".Sudoku_App__container")) {
        return;
      }
      pressTimerFlag = false;
      const click = classifyClick(event);

      if (click.selection) {
        pressTimer = setTimeout(() => {
          // Selektiert die neue Zelle ohne reset
          handleSelection(event, false);
          pressTimerFlag = true;
        }, 130);
        document.addEventListener("mousemove", handleMouseMove);
      } else if (click.reset) {
        // Reset Selection
        sudokuRef.current.resetSelection();
        setSudoku({ ...sudokuRef.current });
      }
    }

    function handlePointerUp(event) {
      if (!event.target.closest(".Sudoku_App__container")) {
        return;
      }
      clearTimeout(pressTimer);
      if (!pressTimerFlag) {
        const reset = event.shiftKey || event.ctrlKey ? false : true;
        handleSelection(event, reset);
      }
      pressTimerFlag = false;
      document.removeEventListener("mousemove", handleMouseMove);
    }

    function handleMouseMove(event) {
      // Selektion mit gedrückter Maustaste
      handleSelection(event, false);
    }

    function handleSelection(event, reset) {
      // Ohne shift | ctrl wird es zuerst resettet
      const id = event.srcElement.id;

      if (id !== "" && Number(id) >= 0 && Number(id) <= 88) {
        // Klick war auf ein Feld im Sudoku
        const [idx_row, idx_col] = id.split("");
        if (reset) {
          // Zuerst Sudoku Selektion zurücksetzen, falls Resetbedingung aktiv
          sudokuRef.current.resetSelection();
        }
        if (!sudokuRef.current.grid[idx_row][idx_col].isSelected) {
          // Geklicktes Feld ist noch nicht selektiert
          sudokuRef.current.grid[idx_row][idx_col].isSelected = true;
          setSudoku({ ...sudokuRef.current });
        }
      }
    }

    function handleKeyDown(event) {
      // Zahleneingaben in Zellen schreiben für ausgewählte Zellen
      // shift -> corner number
      // ctrl -> center number
      if (!event.repeat) {
        // Bei langem Drücken der Taste wird die Funktion nur 1x durchgeführt
        event.preventDefault();
        const key = parseInt(event.code.charAt(event.code.length - 1));

        if (key >= 1 && key <= 9) {
          const shift = event.shiftKey;
          const ctrl = event.ctrlKey;
          // Corner Numbers
          if (shift === true) {
            sudokuRef.current.setCorner(Number(key));
            // Center Numbers
          } else if (ctrl === true) {
            sudokuRef.current.setCenter(Number(key));
          } else {
            // Value Number
            sudokuRef.current.setValue(Number(key));
          }
        } else if (event.key == "Backspace" || event.key == "Delete") {
          // Einträge löschen mit Del | Backspace
          sudokuRef.current.clearCells();
        }
        setSudoku({ ...sudokuRef.current });
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("pointerdown", handlePointerDown);
    document.addEventListener("pointerup", handlePointerUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("pointerdown", handlePointerDown);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, []);

  const isOverlayActive = isPaused || sudoku.isFinished;

  return (
    <div className="MainGrid__container">
      {isOverlayActive && (
        <div className="MainGrid__finished-overlay">
          {sudoku.isFinished ? <FinishOverlay /> : <PauseOverlay />}
        </div>
      )}
      <GridComp sudoku={sudoku} />
    </div>
  );
}

export default MainGrid;

function classifyClick(event_) {
  // selectionClick erkennt Klicks in das Grid
  const selectionClick = event_.target.closest(".GridComp__container")
    ? true
    : false;
  // actionClick erkennt Klicks die eine Aktion ohne Reset durchführen (bsp. NumPad)
  const actionClick =
    event_.target.closest(".NumPad__grid") ||
    event_.target.closest(".NumPadBar__container")
      ? true
      : false;

  const resetClick = selectionClick || actionClick ? false : true;

  return {
    selection: selectionClick,
    action: actionClick,
    reset: resetClick,
  };
}
