import React, { useState, useEffect } from "react";
import "./Header.css";
import Logo_Fullsize from "../../Images/logo_retro.svg";
import Logo_Icon from "../../Images/icon_retro.svg";
import MenuBar from "../../Images/Icons/menu-2.svg";
import Maximize_Icon from "../../Images/Icons/maximize.svg";
import Minimize_Icon from "../../Images/Icons/minimize.svg";
import useViewportWidth from "../Sudoku/Hooks/useViewport";

function Header() {
  const width = useViewportWidth();

  function MenuItems() {
    return (
      <ul id="NoBulletPoints">
        <li>
          <a href="/">Play</a>
        </li>
        {/* <li>
          <a href="/Custom">Build a Sudoku</a>
        </li> */}
        <li>
          <a href="/HowTo">Rules</a>
        </li>
        {/* <li>
          <a href="/Solver">Solver</a>
        </li> */}
        <li>
          <a href="/AboutMe">About Me</a>
        </li>
      </ul>
    );
  }

  function DesktopHeader() {
    return (
      <div className="Header_Desktop__container">
        <div className="Header_Desktop__content">
          <a href="/">
            <img src={Logo_Fullsize} height={40} alt={"Sudoku-Hub Logo"} />
          </a>
          <MenuItems />
        </div>
      </div>
    );
  }

  function MobileHeader() {
    const [isDropdown, setDropdown] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(
      document.fullscreenElement !== null
    );

    function DropdownMenu() {
      return (
        <div className="Header_Mobile__Dropdown">
          <MenuItems />
        </div>
      );
    }

    function checkFullscreen() {
      setIsFullscreen(document.fullscreenElement !== null);
    }

    useEffect(() => {
      // Event-Listener hinzufügen, um Fullscreen-Änderungen zu überwachen
      document.addEventListener("fullscreenchange", checkFullscreen);

      // Cleanup bei Komponentendemontage
      return () => {
        document.removeEventListener("fullscreenchange", checkFullscreen);
      };
    }, []);

    function enterFullscreen() {
      setIsFullscreen(true);
      const element = document.documentElement; // Die gesamte Seite
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Für Safari
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // Für IE11
      }
    }

    function exitFullscreen() {
      setIsFullscreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    return (
      <div className="Header_Mobile__container">
        <div className="Header_Mobile__Bar">
          {isFullscreen ? (
            <img
              src={Minimize_Icon}
              height={25}
              alt={"Menu Bar"}
              onClick={exitFullscreen}
            />
          ) : (
            <img
              src={Maximize_Icon}
              height={25}
              alt={"Menu Bar"}
              onClick={enterFullscreen}
            />
          )}
          <a href="/">
            <img src={Logo_Icon} height={40} alt={"Sudoku-Hub Logo"} />
          </a>
          <img
            src={MenuBar}
            height={25}
            alt={"Menu Bar"}
            // className="Header_Mobile__MenuBar"
            onClick={() => {
              setDropdown(!isDropdown);
            }}
          />
        </div>
        {isDropdown ? <DropdownMenu /> : <></>}
      </div>
    );
  }
  return width < 1000 ? <MobileHeader /> : <DesktopHeader />;
}

export default Header;
