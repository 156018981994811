import React, { useContext } from "react";
import SudokuContext from "../../../Context/SudokuContext";
import "./NumPad.css";

function NumPadDigit({ number }) {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);

  function handleClick(event) {
    const key = number;
    const shift = event.shiftKey || sudoku.inputMode[1] ? true : false;
    const ctrl = event.ctrlKey || sudoku.inputMode[2] ? true : false;

    if (number == "X") {
      sudokuRef.current.clearCells();
    } else if (shift === true) {
      sudokuRef.current.setCorner(Number(key));
    } else if (ctrl === true) {
      sudokuRef.current.setCenter(Number(key));
    } else {
      sudokuRef.current.setValue(Number(key));
    }
    setSudoku({ ...sudokuRef.current });
  }
  return (
    <button
      className="NumPad_Digit ButtonPressEffect"
      id={number === "X" ? "DeleteButton" : undefined}
      onClick={(event) => {
        event.nativeEvent.pointerType != "touch" && handleClick(event);
      }}
      onTouchStart={(event) => {
        handleClick(event);
      }}
    >
      {number}
    </button>
  );
}

export default NumPadDigit;
