import { create } from "zustand";

const useStopwatchStore = create((set, get) => ({
  count: 0,
  isPaused: false, // Standardmäßig nicht pausiert wegen overlay über maingrid
  interval: null, // Speichert die Intervall-ID

  startStopwatch: () => {
    // Startet das Intervall und den counter
    if (get().isPaused) {
      const interval = setInterval(() => {
        set((state) => ({ count: state.count + 1 }));
      }, 1000);
      set({ isPaused: false, interval });
    }
  },

  pauseStopwatch: () => {
    // pausiert den counter
    clearInterval(get().interval);
    set({ isPaused: true });
  },

  resetStopwatch: () => {
    // Setzt den counter zurück und startet das Intervall neu
    clearInterval(get().interval);
    set({ count: 0 });
    const interval = setInterval(() => {
      set((state) => ({ count: state.count + 1 }));
    }, 1000);
    set({ isPaused: false, interval });
  },
}));

export default useStopwatchStore;
