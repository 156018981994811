import React from "react";
import "./HowToPage.css";

function HowToPage() {
  return (
    <div className="HowToPage__container">
      <div className="HowToPage__content">
        <h1>How to play Sudoku</h1>
        <span>
          Sudoku is a logic-based number puzzle that’s both fun and challenging.
          The goal is to fill the grid so that every row, column, and 3x3 box
          contains the numbers 1 through 9 without repetition. Whether you’re
          new to Sudoku or looking to improve your solving skills, this
          step-by-step guide will help you become a Sudoku pro in no time!
        </span>
        <h2>1. Basic Rules of Sudoku</h2>
        <span>
          The rules of Sudoku are simple yet create endless possibilities for
          puzzles of varying difficulty. Here’s how it works:
          <ul>
            <li>
              <b>The Grid:</b> Sudoku is played on a 9x9 grid divided into nine
              3x3 subgrids (also called “boxes”).
            </li>
            <li>
              <b>The Goal:</b> Fill each row, column, and box with the numbers 1
              through 9.
            </li>
            <li>
              <b>No Repetition:</b> Numbers cannot repeat within a row, column,
              or box.
            </li>
            <li>
              <b> Starting Clues:</b> The puzzle begins with some numbers
              already filled in. Your task is to figure out where the remaining
              numbers go!
            </li>
          </ul>
        </span>
        <h2>2. Step-by-Step Guide</h2>
        <span>
          Follow these steps to solve a Sudoku puzzle:
          <h3>Step 1: Start Simple</h3>
          Look for rows, columns, or boxes that already have many numbers filled
          in. These are the easiest places to begin.
          <h3>Step 2: Use the Process of Elimination</h3>
          For each empty cell, ask yourself:
          <ul>
            <li>Which numbers are missing in this row?</li>
            <li>Which numbers are missing in this column?</li>
            <li>Which numbers are missing in this box?</li>
          </ul>
          Fill in the number that fits without breaking the rules.
          <h3>Step 3: Pencil Marks (Optional)</h3>
          If you’re unsure, write down possible numbers (pencil marks) for each
          cell. For example, if a cell could be a 3 or 7, jot those down
          lightly.
          <h3>Step 4: Check and Cross-Check</h3>
          After filling in numbers, check if your solution fits in all rows,
          columns, and boxes. If not, re-evaluate your steps.
        </span>
        <h2>3. Common Mistakes to Avoid</h2>
        <span>
          <ul>
            <li>
              <b>Guessing Too Soon:</b> Avoid making guesses until you've
              exhausted all logical steps. Guessing often leads to mistakes.
            </li>
            <li>
              <b> Ignoring Pencil Marks:</b> Use them to keep track of
              possibilities instead of solving everything in your head.
            </li>
            <li>
              <b>Overlooking Rows/Columns:</b> Always check all directions
              (rows, columns, and boxes) for clues.
            </li>
          </ul>
        </span>
        <h2>4. Ready to Play?</h2>
        <span>
          Now that you know the basics, it’s time to put your skills into
          practice!{" "}
          <b>
            <a href="/">Try solving a puzzle today on our page!</a>
          </b>
          Whether you’re looking for a quick beginner puzzle or a challenging
          expert grid, our Sudoku games are perfect for everyone.
        </span>
      </div>
    </div>
  );
}

export default HowToPage;
