import React, { useState, useEffect, useRef } from "react";
import "./CellComp.css";

function CellComp(props) {
  const { subCell } = props;
  const index = subCell.index[0].toString() + subCell.index[1].toString();

  function ValueCell() {
    return (
      <div id={index} style={fontSizeStyle}>
        {subCell.value}
      </div>
    );
  }
  function IndexCell() {
    return (
      <div id={index} className="Index_Cell">
        <div id={index} className="UpperCornerLeft">
          {subCell.corner[0]}
        </div>
        <div id={index} className="UpperCornerRight">
          {subCell.corner[1]}
        </div>
        <div id={index} className="Center">
          {/* {subCell.center} */}
          {subCell.center.map((number, centerIdx) => (
            <div id={index} key={centerIdx}>
              {number}
            </div>
          ))}
        </div>
        <div id={index} className="LowerCornerLeft">
          {subCell.corner[2]}
        </div>
        <div id={index} className="LowerCornerRight">
          {subCell.corner[3]}
        </div>
      </div>
    );
  }

  const selectedBGrgb = "rgba(115,189,168,1)";
  var settingColorRGB = "rgba(111,86,67,1)";
  var solvingColorRGB = "rgba(111,86,67,0.8)";
  const errorColorRGB = "rgba(204,107,73,0.8)";
  const errorColorSettingRGB = "rgba(204,107,73,1)";

  if (subCell.isError === true) {
    settingColorRGB = errorColorSettingRGB;
    solvingColorRGB = errorColorRGB;
  }

  function blendColors(color1, color2) {
    const rgbaRegex = /rgba\((\d+),(\d+),(\d+),([\d.]+)\)/;

    // Extrahiere die RGBA-Komponenten der Farben
    const [, r1, g1, b1, a1] = color1.match(rgbaRegex);
    const [, r2, g2, b2, a2] = color2.match(rgbaRegex);

    // Berechne den Durchschnitt der Farbwerte
    const blendedR = (parseInt(r1) + parseInt(r2)) / 2;
    const blendedG = (parseInt(g1) + parseInt(g2)) / 2;
    const blendedB = (parseInt(b1) + parseInt(b2)) / 2;
    const blendedA = (parseFloat(a1) + parseFloat(a2)) / 2;

    // Erstelle den gemischten Farbstring im RGBA-Format
    const blendedColor = `rgba(${blendedR},${blendedG},${blendedB},${blendedA})`;

    const rgbaValues = blendedColor.replace(/rgba|\(|\)|\s/g, "").split(",");

    // Extrahiere die RGB-Werte und konvertiere sie in Ganzzahlen
    const red = parseInt(rgbaValues[0]);
    const green = parseInt(rgbaValues[1]);
    const blue = parseInt(rgbaValues[2]);

    // Erzeuge einen neuen RGB-Wert als String
    const rgbColor = `rgb(${red}, ${green}, ${blue})`;

    return rgbColor;
  }

  // DIV SIZE
  const divRef = useRef(null);
  const [divSize, setDivSize] = useState("100%");

  function getDivDimensions() {
    if (divRef.current) {
      const { clientWidth, clientHeight } = divRef.current;
      setDivSize(clientWidth < clientHeight ? clientWidth : clientHeight);
      return divSize;
    }
    return "100%";
  }
  useEffect(() => {
    getDivDimensions();
  }, []);

  const fontSize = (divSize / 1.6).toString() + "px";
  const fontSizeStyle = { fontSize: fontSize };
  const fontSizeIndex = (divSize / 5.5).toString() + "px";

  const divStyle = {
    backgroundColor: subCell.isSelected
      ? blendColors(subCell.color, selectedBGrgb)
      : subCell.color,
    color: subCell.isLocked ? settingColorRGB : solvingColorRGB,
    fontSize: fontSizeIndex,
    lineHeight: fontSizeIndex,
    padding: "0.3em",
  };

  return (
    <div
      id={index}
      className="CellComp__container"
      style={divStyle}
      ref={divRef}
      onContextMenu={(event) => {
        event.preventDefault();
      }}
    >
      {subCell.value == null ? <IndexCell /> : <ValueCell />}
    </div>
  );
}

export default CellComp;
