import { getDependendGroups } from "../Solver/Scripts/dependendGroups";
import { isValidSudokuString } from "../GlobalScripts/isValidSudokuString";
import { Cell } from "./Cell";
import { Solver } from "./Solver";
import { cloneDeep } from "lodash";

export class Sudoku {
  constructor() {
    this.grid = null;
    this.simplegrid = null;
    this.simplegridUnsolved = null;
    this.string = null;
    this.stringUnsolved = null;
    this.isSetting = false;
    this.isFinished = false;
    this.isSolvable = false;
    this.inputMode = [true, false, false];
    // this.difficulty = "hard";
    this.score = 73;
    this.dependendGroups = [];
    this.gridHistory = [];

    this.initializeSudoku();
  }

  initializeSudoku() {
    // Initialisiert "this.grid" mit leeren Zellen
    this.grid = Array(9)
      .fill(null)
      .map(() => Array(9).fill(null));
    for (let idx_col = 0; idx_col < 9; idx_col++) {
      for (let idx_row = 0; idx_row < 9; idx_row++) {
        this.grid[idx_col][idx_row] = new Cell([idx_col, idx_row]);
      }
    }
    this.dependendGroups = getDependendGroups(this.grid);

    this.simplegrid = Array(9)
      .fill(null)
      .map(() => Array(9).fill(0));
  }

  resetSelection() {
    // Setzt die Selektion zurück. "isSelected" wird von allen Zellen auf false gesetzt
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].isSelected = false;
        }
      }
    }
  }

  setCorner(number_) {
    // Schreibt den übergebenen Wert in alle selektierten Zellen als "Corner Note"
    let updateFlag = false;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].setCorner(number_, this.isSetting);
          updateFlag = true;
        }
      }
    }
    if (updateFlag) {
      this.updateHistory();
    }
  }

  setCenter(number_) {
    // Schreibt den übergebenen Wert in alle selektierten Zellen als "Center Note"
    let updateFlag = false;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].setCenter(number_, this.isSetting);
          updateFlag = true;
        }
      }
    }
    if (updateFlag) {
      this.updateHistory();
    }
  }

  setValue(number_) {
    // Schreibt den übergebenen Wert in alle selektierten Zellen als "Value"
    let updateFlag = false;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].setValue(number_, this.isSetting);
          updateFlag = true;
        }
      }
    }
    this.full2simple();
    if (updateFlag) {
      this.updateHistory();
    }
  }

  clearCells() {
    // Löscht bei allen selektierten Zellen den Inhalt (Value, Corner, Center)
    let updateFlag = false;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isSelected) {
          this.grid[row][col].clearAll(this.isSetting);
          updateFlag = true;
        }
      }
    }
    this.full2simple();
    if (updateFlag) {
      this.updateHistory();
    }
  }

  updateHistory() {
    // Fügt das aktuelle grid der Historie hinzu (für Redo-Funktion)
    this.gridHistory.push(cloneDeep(this.grid));

    if (this.gridHistory.length > 30) {
      this.gridHistory.shift();
    }
  }

  stringUnsolved2full() {
    // Übersetzt den "this.stringUnsolved" in "this.grid"
    // Hauptsächlich für Start aus Datenbank notwendig
    this.initializeSudoku();
    let idxString = 0;
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        let value = this.stringUnsolved[idxString];
        this.simplegrid[row][col] = value;
        if (value != 0) {
          this.grid[row][col].value = parseInt(value);
          this.grid[row][col].isLocked = true;
          this.grid[row][col].color = "rgba(210,162,76,0.2)";
        }
        idxString++;
      }
    }
    this.updateHistory();
  }

  setStringUnsolved(string_) {
    // Überprüft und importiert einen string in "stringUnsolved"
    if (isValidSudokuString(string_)) {
      this.stringUnsolved = string_;
    }
  }

  checkForError() {
    // Überprüft, ob ein Fehler im Sudoku vorliegt oder es vollständig gelöst ist
    // Alle isError zurücksetzen
    let hasError = false;
    let isFullyFilled = true;

    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].isError) {
          this.grid[row][col].isError = false;
        }
        if (this.grid[row][col].value === null) {
          isFullyFilled = false;
        }
      }
    }
    // Nach Fehlern suchen und isError setzen
    for (const dependendGroup of this.dependendGroups) {
      for (let i1 = 0; i1 < 8; i1++) {
        for (let i2 = i1 + 1; i2 < 9; i2++) {
          if (
            dependendGroup[i1].value === dependendGroup[i2].value &&
            dependendGroup[i1].value != null
          ) {
            dependendGroup[i1].isError = true;
            dependendGroup[i2].isError = true;
            hasError = true;
          }
        }
      }
    }

    if (isFullyFilled && !hasError) {
      this.isFinished = true;
      this.resetSelection();
    }
  }

  full2simple() {
    // Überträgt das fullgrid zu simplegrid
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.grid[row][col].value != null) {
          this.simplegrid[row][col] = this.grid[row][col].value;
        } else {
          this.simplegrid[row][col] = 0;
        }
      }
    }
    this.checkForError();
  }

  simple2full() {
    // Überträgt das simplegrid zu fullgrid
    for (let row = 0; row < 9; row++) {
      for (let col = 0; col < 9; col++) {
        if (this.simplegrid[row][col] != 0) {
          this.grid[row][col].value = this.simplegrid[row][col];
        }
      }
    }
  }
}
