import "./Sudoku_Apps.css";
import React, { useState, useEffect, useContext } from "react";
import MainGrid from "../MainGrid/MainGrid";
import Database from "../Database/Database";
import NumPad from "../NumPad.js/NumPad";
import NumPadBar from "../NumPad.js/NumPadBar";
import ActionBar from "../ActionBar/ActionBar";
import Plugin_Solver from "../Solver/Plugin_Solver";
import SudokuContext from "../../../Context/SudokuContext";
import useStopwatchStore from "../../../Stores/stopwatchStore";

function Sudoku_Preset() {
  const { sudoku } = useContext(SudokuContext);
  const isPaused = useStopwatchStore((state) => state.isPaused);

  return (
    <div className="Sudoku_App__container">
      {(sudoku.isFinished || isPaused) && (
        <div className="Sudoku_App__overlay"></div>
      )}
      <div className="Sudoku_App__grid">
        <div className="Sudoku_App__database">
          <Database />
        </div>
        <MainGrid />
      </div>
      <div className="Sudoku_App__toolbar">
        <div className="Sudoku_App__numpadbar">
          <NumPadBar />
        </div>
        <div className="Sudoku_App__controlboard">
          <NumPad />
          <ActionBar />
          {/* <Plugin_Solver /> */}
        </div>
      </div>
    </div>
  );
}

export default Sudoku_Preset;
