import React from "react";
import "./PresetPage.css";
import { Helmet } from "react-helmet";

import Sudoku_Preset from "../../Components/Sudoku/Sudoku_Apps/Sudoku_Preset";

function PresetPage() {
  return (
    <div className="PresetPage__container">
      <MetaTags />
      <div className="PresetPage__app-background">
        <div className="PresetPage__sudokuapp">
          <Sudoku_Preset />
        </div>
      </div>
      <div className="PresetPage__text-container">
        <div className="PresetPage__text-section">
          <h1>About the Sudokus</h1>
          <span>
            The Sudoku puzzles on this site are generated using my proprietary
            generator, ensuring a unique and diverse set of challenges.
            Difficulty levels are modeled after puzzles from the NY Times,
            making them especially appealing to fans of those puzzles. If you
            enjoy NY Times Sudoku, you're sure to appreciate the quality of
            puzzles offered by Sudoku-Hub. Best of all, all our Sudokus are
            completely free, so feel free to give them a try. Wishing you
            success and, of course, lots of fun!
          </span>
          <h1>How to Play</h1>
          <span>
            Explore diverse candidate possibilities with our features –
            'Number,' 'Corner,' and 'Center.' Utilize 'Number' confidently for
            digits you are certain belong in a specific cell. Opt for 'Corner'
            (hold Shift) to place candidates in the corners of cells, allowing a
            maximum of 4 candidates per cell. Alternatively, choose 'Center'
            (hold Ctrl) to position candidates in the middle of cells,
            accommodating up to 9 candidates per cell. Effortlessly set common
            candidates across multiple cells by holding the mouse button or
            using shift/ctrl.
          </span>
          <h1>Basic Sudoku rules</h1>
          <span>
            Sudoku is a classic number puzzle game played on a 9x9 grid. The
            objective is to fill each row, column, and 3x3 subgrid with the
            numbers 1 to 9, ensuring that no repetition occurs within any row,
            column, or subgrid. The puzzle starts with some numbers already
            filled in, acting as clues. The challenge lies in logically deducing
            and strategically placing the remaining numbers to complete the
            grid. Keep in mind that every row, column, and subgrid must contain
            all digits from 1 to 9. Sudoku is a game of logic and deduction,
            offering a rewarding experience for players of all skill levels.
          </span>
        </div>
      </div>
    </div>
  );
}

function MetaTags() {
  return (
    <Helmet>
      <title>Play Sudoku for free!</title>
      <meta
        name="description"
        content="Enjoy solving Sudoku puzzles online for free! Choose from easy, medium, hard, and expert levels to challenge your brain. No downloads required."
      />
      <meta
        name="keywords"
        content="Sudoku, play Sudoku online, free Sudoku puzzles, online Sudoku game, challenging Sudoku, Sudoku levels, daily Sudoku, brain puzzles"
      />
      <meta name="author" content="Sudoku-Hub" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="UTF-8" />
    </Helmet>
  );
}

export default PresetPage;
