import React, { useState, useContext, useEffect } from "react";
import "./NumPad.css";
import NumPadDigit from "./NumPadDigit";
import useViewportWidth from "../Hooks/useViewport";

function NumPad() {
  const Digits = [1, 2, 3, 4, 5, 6, 7, 8, 9, "X"];
  const viewportWidth = useViewportWidth();

  // const numpadMode =
  function NumPad() {
    return (
      <div className="NumPad__container">
        <div className="NumPad__grid">
          {Digits.map((digit, index) => (
            <NumPadDigit key={index} number={digit} />
          ))}
        </div>
      </div>
    );
  }

  return <NumPad />;
}

export default NumPad;
