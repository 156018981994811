import "./Sudoku_Apps.css";
import React, { useRef, useState, useContext } from "react";
import MainGrid from "../MainGrid/MainGrid";
import NumPad from "../NumPad.js/NumPad";
import Plugin_Solver from "../Solver/Plugin_Solver";
import Database from "../Database/Database";
import NumPadBar from "../NumPad.js/NumPadBar";
import ActionBar from "../ActionBar/ActionBar";
import {
  fullLogicSolve,
  handleStep,
  SudokuStats,
} from "../Solver/Scripts/additionalFunctions";
import { initialCandidates } from "../Solver/Scripts/updateCandidates";
import useStopwatchStore from "../../../Stores/stopwatchStore";
import SudokuContext from "../../../Context/SudokuContext";

function Sudoku_Solver() {
  const { sudoku, setSudoku, sudokuRef } = useContext(SudokuContext);
  const isPaused = useStopwatchStore((state) => state.isPaused);

  function logicSolve() {
    const result = fullLogicSolve(sudokuRef.current.grid);
    console.log("RESULT: ", result);
  }

  function solveStep() {
    const stats = new SudokuStats();
    const result = handleStep(sudokuRef.current.grid, stats);
    setSudoku({ ...sudokuRef.current });
    console.log("Result: ", result);
  }

  return (
    <div className="Sudoku_App__container">
      {(sudoku.isFinished || isPaused) && (
        <div className="Sudoku_App__overlay"></div>
      )}
      <div className="Sudoku_App__grid">
        <div className="Sudoku_App__database">
          <Database />
        </div>
        <MainGrid />
      </div>
      <div className="Sudoku_App__toolbar">
        <div className="Sudoku_App__numpadbar">
          <NumPadBar />
        </div>
        <div className="Sudoku_App__controlboard">
          <NumPad />
          <ActionBar />
          <Plugin_Solver />
          <button
            onClick={() => {
              initialCandidates(sudokuRef.current.grid);
              setSudoku({ ...sudokuRef.current });
            }}
          >
            CANDIDATES
          </button>
          <button onClick={logicSolve}>Full Logic Solve</button>
          <button onClick={solveStep}>STEP</button>
        </div>
      </div>
    </div>
  );
}

export default Sudoku_Solver;
