import React, { useContext } from "react";
import "./ActionBar.css";
import { cloneDeep } from "lodash";
import { getSQLSudoku } from "../GlobalScripts/getSQLSudoku";
import { getDependendGroups } from "../Solver/Scripts/dependendGroups";
import { initialCandidates } from "../Solver/Scripts/updateCandidates";
import { Sudoku } from "../Backend/Sudoku";
import SudokuContext from "../../../Context/SudokuContext";
import useDifficultyStore from "../../../Stores/difficultyStore";
import useStopwatchStore from "../../../Stores/stopwatchStore";

import ActionBarButton from "./ActionBarButton";
import Undo_Logo from "../../../Images/Icons/undo_minified.svg";
import Hint_Logo from "../../../Images/Icons/hint_minified.svg";
import New_Logo from "../../../Images/Icons/new_minified.svg";

function ActionBar() {
  const { setSudoku, sudokuRef } = useContext(SudokuContext);
  const { difficulty } = useDifficultyStore();
  const resetStopwatch = useStopwatchStore((state) => state.resetStopwatch);

  function undo() {
    // Setzt einen Schritt zurück
    const undoIdx = sudokuRef.current.gridHistory.length - 2;

    if (undoIdx > 0) {
      sudokuRef.current.grid = cloneDeep(
        sudokuRef.current.gridHistory[undoIdx]
      );
      sudokuRef.current.gridHistory.pop();
      sudokuRef.current.resetSelection();
      sudokuRef.current.dependendGroups = getDependendGroups(
        sudokuRef.current.grid
      );

      setSudoku({ ...sudokuRef.current });
    }
  }

  function newSudoku() {
    // Läd ein neues Sudoku aus der SQL Datenbank
    resetStopwatch();
    getSQLSudoku(difficulty).then((sudokuString) => {
      sudokuRef.current = new Sudoku();
      sudokuRef.current.stringUnsolved = sudokuString;
      sudokuRef.current.stringUnsolved2full();
      setSudoku({ ...sudokuRef.current });
    });
  }

  // Erzeugt einen Hinweis für den nächsten Schritt
  function hint() {}

  // Erzeugt alle Candidates als Center Note
  function getCandidates() {
    sudokuRef.current.grid = initialCandidates(sudokuRef.current.grid);
    sudokuRef.current.updateHistory();
    setSudoku({ ...sudokuRef.current });
  }

  return (
    <div className="ActionBar__container">
      <ActionBarButton
        image={New_Logo}
        clickFunction={newSudoku}
        subtext={"New"}
        altText={"Button for new Sudoku"}
      />
      <ActionBarButton
        image={Undo_Logo}
        clickFunction={undo}
        subtext={"Undo"}
        altText={"Button for Undo"}
      />
      <ActionBarButton
        image={Hint_Logo}
        clickFunction={getCandidates}
        subtext={"Notes"}
        altText={"Button for Hint"}
      />
    </div>
  );
}

export default ActionBar;
