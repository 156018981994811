import "./App.css";
import React, { useState, useRef } from "react";
import PresetPage from "./Pages/PresetPage/PresetPage";
import CustomPage from "./Pages/CustomPage/CustomPage";
import SolverPage from "./Pages/SolverPage/SolverPage";
import CreatorPage from "./Pages/CreatorPage/CreatorPage";
import AnalyzerPage from "./Pages/AnalyzerPage/AnalyzerPage";
import HowToPage from "./Pages/HowToPage/HowToPage";
import AboutMePage from "./Pages/AboutMePage/AboutMePage";
import Header from "./Components/Header/Header";
import { Routes, Route } from "react-router-dom";
import { Sudoku } from "./Components/Sudoku/Backend/Sudoku";
import SudokuContext from "./Context/SudokuContext";

function App() {
  const sudokuRef = useRef(new Sudoku());
  const [sudoku, setSudoku] = useState(sudokuRef.current);
  return (
    <div className="App__container">
      <SudokuContext.Provider
        value={{
          sudoku,
          setSudoku,
          sudokuRef,
        }}
      >
        <Header />
        <div className="App__page-content">
          <Routes>
            <Route path="/" element={<PresetPage />} />
            <Route path="/Custom" element={<CustomPage />} />
            <Route path="/HowTo" element={<HowToPage />} />
            <Route path="/Creator" element={<CreatorPage />} />
            <Route path="/Analyzer" element={<AnalyzerPage />} />
            <Route path="/Solver" element={<SolverPage />} />
            <Route path="/AboutMe" element={<AboutMePage />} />
          </Routes>
        </div>
      </SudokuContext.Provider>
    </div>
  );
}

export default App;
