import React from "react";
import "./ActionBarButton.css";
import useStopwatchStore from "../../../Stores/stopwatchStore";

function ActionBarButton(props) {
  const image = props.image;
  const subtext = props.subtext;
  const clickFunction = props.clickFunction;
  const altText = props.altText;

  const { startStopwatch } = useStopwatchStore();

  return (
    <div
      className="ActionBarButton__container"
      onClick={() => {
        clickFunction();
        startStopwatch();
      }}
    >
      <img src={image} height={48} alt={altText} />
      <div className="ActionBarButton_Subtext">{subtext}</div>
    </div>
  );
}

export default ActionBarButton;
