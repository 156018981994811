import React, { useState, useEffect, useRef, useContext } from "react";
import "./Stopwatch.css";
import SudokuContext from "../../../Context/SudokuContext";
import useStopwatchStore from "../../../Stores/stopwatchStore";
import PauseIcon from "../../../Images/Icons/player-pause.svg";
import PlayIcon from "../../../Images/Icons/player-play.svg";
import { getTimeFromSeconds } from "../GlobalScripts/getTimeFromSeconds";

function Stopwatch() {
  const { count, isPaused, startStopwatch, pauseStopwatch, resetStopwatch } =
    useStopwatchStore();
  const { sudoku } = useContext(SudokuContext);

  // Benötigt für quadratisches div des Pause-Buttons
  const pauseRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function updateWidth() {
      if (pauseRef.current) {
        const height = pauseRef.current.offsetHeight;
        setWidth(height); // Breite = Höhe
      }
    }
    updateWidth();
    // Aktualisierung bei Fenstergröße ändern
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  // Startet beim ersten rendern die Stoppuhr
  useEffect(() => {
    startStopwatch();
    console.log("Start Stopwatch");
  }, []);

  useEffect(() => {
    if (sudoku.isFinished) {
      pauseStopwatch();
    }
  }, [sudoku.isFinished]);

  return (
    <div className="Stopwatch__container">
      {getTimeFromSeconds(count)}
      <div
        className="Stopwatch__pausebutton"
        onClick={isPaused ? startStopwatch : pauseStopwatch}
        ref={pauseRef}
        style={{ width: width }}
      >
        {isPaused ? (
          <img
            src={PlayIcon}
            height={width / 2}
            alt={"Play button for the stopwatch"}
          />
        ) : (
          <img
            src={PauseIcon}
            height={width / 2}
            alt={"Pause button for the stopwatch"}
          />
        )}
      </div>
    </div>
  );
}

export default Stopwatch;
