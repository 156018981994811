export function getFinishedScore(difficulty, timeInSeconds) {
  // Define the scoring rules
  const scoringRules = {
    easy: { maxTime: 600, fullScore: 50 }, // 600 seconds = 10 minutes
    medium: { maxTime: 900, fullScore: 75 }, // 900 seconds = 15 minutes
    hard: { maxTime: 1200, fullScore: 100 }, // 1200 seconds = 20 minutes
  };

  // Check if difficulty is valid
  if (!scoringRules[difficulty]) {
    throw new Error(
      "Invalid difficulty level. Use 'easy', 'medium', or 'hard'."
    );
  }

  const { maxTime, fullScore } = scoringRules[difficulty];

  // Calculate the score
  if (timeInSeconds <= maxTime) {
    return Math.round(fullScore); // Full score for completing within the max time
  }

  // Convert overtime to seconds
  const overTime = timeInSeconds - maxTime;

  // If player took 15 minutes (900 seconds) too long or more, only half the points
  if (overTime >= 900) {
    return Math.round(fullScore / 2);
  }

  // Linearly decrease points based on the overtime (up to 900 seconds)
  const penalty = (overTime / 900) * (fullScore / 2);
  return Math.round(Math.max(fullScore - penalty, fullScore / 2));
}
