import { getDependendGroups } from "./dependendGroups";

export function updateCandidates(grid) {
  // Iteriert durch alle dependenGrouds und sucht nach Zellen mit
  // einer vollen Zahl. Diese Zahl wird bei den abhängigen Zellen
  // als antiCandidate hinzugefügt
  const dependendGroups = getDependendGroups(grid);

  for (const dependendGroup of dependendGroups) {
    for (let cell = 0; cell < 9; cell++) {
      if (dependendGroup[cell].value != null) {
        dependendGroup[cell].candidates = [];
        let antiNumber = dependendGroup[cell].value;
        for (let index = 0; index < 9; index++) {
          dependendGroup[index].removeCandidates([antiNumber]);
          dependendGroup[index].candidates2center();
        }
      }
    }
  }

  return grid;
}

export function initialCandidates(grid) {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (grid[row][col].value === null) {
        grid[row][col].candidates = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        grid[row][col].candidates2center();
      }
    }
  }
  grid = updateCandidates(grid);
  return grid;
}
