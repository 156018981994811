export async function getSQLSudoku(grade_) {
  const sampleString =
    "600000000000008509570300004003450700000007001080000006020600000000030000960010080";
  try {
    const response = await fetch("/api/loadSudoku" + grade_ + ".php");
    const data = await response.json();
    return data[0].sudoku_string;
  } catch (error) {
    console.log(error);
    return sampleString;
  }
}
