import React from "react";
import "./PauseOverlay.css";
import { ReactSVG } from "react-svg";
import useStopwatchStore from "../../../Stores/stopwatchStore";
import play_icon from "../../../Images/Icons/player-play.svg";

function PauseOverlay() {
  const startStopwatch = useStopwatchStore((state) => state.startStopwatch);

  return (
    <div className="PauseOverlay__container">
      <div className="PauseOverlay__heading">Paused</div>
      <div className="PauseOverlay__buttons">
        <button className="ButtonPressEffect" onClick={startStopwatch}>
          <ReactSVG src={play_icon} />
        </button>
      </div>
    </div>
  );
}

export default PauseOverlay;
